export const navLinks = [
  {
    id: 1,
    title: 'How it works',
    anchor: 'how-it-works',
  },
  {
    id: 2,
    title: 'How to use',
    anchor: 'how-to-use',
  },
  {
    id: 3,
    title: 'Use cases',
    anchor: 'use-cases',
  },
  {
    id: 4,
    title: 'Try it out',
    anchor: 'try-it-out',
  },
  {
    id: 5,
    title: "Let's talk",
    anchor: 'contact-us',
  },
];
