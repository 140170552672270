import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './web.config';

import App from './App';

const browserHistory = createBrowserHistory();

/* eslint-disable no-undef */
const root = document.getElementById('root');

render(
  <BrowserRouter history={browserHistory}>
    <App />
  </BrowserRouter>,
  root,
);
