import slide_1 from '../../asset/resource/slide-1.png';
import slide_2 from '../../asset/resource/slide-2.png';
import slide_3 from '../../asset/resource/slide-3.png';
import slide_31 from '../../asset/resource/slide-3-1.png';

import slide_1_step_1 from '../../asset/resource/s1_step-1.png';
import slide_1_step_2 from '../../asset/resource/s1_step-2.png';
import slide_2_step_1 from '../../asset/resource/s2_step-1.png';
import slide_2_step_2 from '../../asset/resource/s2_step-2.png';
import slide_31_step_1 from '../../asset/resource/s31_step-1.png';
import slide_31_step_2 from '../../asset/resource/s31_step-2.png';

export const items = [
  {
    slideImage: slide_31,
    stpImage_1: slide_31_step_1,
    stpImage_2: slide_31_step_2,
    preHeading: 'Automated 3D Scanning Use cases',
    heading: 'MANUFACTURING COMPONENT VALIDATION',
    description: `The human eye can't detect an aberration as small as a millimeter. Yet, that's all it takes to impact fine-tuned manufacturing equipment performance. It often causes severe throughput consequences.
    <br/><br/>Time constraints force random sampling for traditional quality insurance inspections. There's a more efficient solution. <br/><br/>
    Automated inspections using CAD models and 3D scanning technology enhance inspection precision so you can detect costly disruptive defects before integration. You'll increase your revenue and your reputation. 
    `,
    bgColor: 'white',
    bgImage: 'transparent',
    step_1_hasExternalResource: false,
  },
  {
    slideImage: slide_3,
    stpImage_1: slide_2_step_1,
    stpImage_2: slide_2_step_2,
    preHeading: 'Automated 3D Scanning Use cases',
    heading: 'PACKAGING QUALITY ASSURANCE',
    description: `Packaging inspection is complex. It gets even harder in industries where contents are perishable — such as food and medicine. Packing systems must handle frequent design changes. They also face other common process issues, such as defects and damage.
    <br/><br/> Using onsite subject matter experts for quality control is slow and costly. There's a more efficient solution.  <br/><br/>
    3D scanning solutions have a 360-degree turntable. They create accurate 3D packaging models and identify defects. 
    The enhancement complements existing human quality control, which becomes a remote process.
    `,
    bgColor: 'white',
    bgImage: 'linear-gradient(180deg, #CBCBCB 0%, #E8E8E8 100%)',
    step_1_hasExternalResource: false,
  },
  {
    slideImage: slide_1,
    stpImage_1: slide_1_step_1,
    stpImage_2: slide_1_step_2,
    preHeading: 'Automated 3D Scanning Use cases',
    heading: 'GAP & FLUSH INSPECTION',
    description: `3D scanning during product assembly enhances component alignment. <br/><br />
    Gap and flush quality assurance is vital to the automobile industry. Poor gap and flush alignment impact vehicle aerodynamic performance and fuel efficiency. Drivers experience increased noise. With so much at stake, manufacturers often accept slow manual inspections.
    <br/><br/>
    Add SoftServe’s robotics AVI automated 3D scanning solution to your production line. It measures all product points of interest in real time. Collected data generates instant notifications about flush and gap fault detection.
    <br/></br>`,
    bgColor: 'dark',
    openVideoText: 'Watch the video to see how it works. ',
    bgImage: 'linear-gradient(180deg, #CBCBCB 0%, #E8E8E8 100%)',
    step_1_hasExternalResource: true,
  },
];
