import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import Modal from '../Modal/Modal';

import { useCheckMobile } from '../../hooks';
import { sanitizeHtml } from 'utils';

const Slide = ({
  slideImage,
  stpImage_1,
  stpImage_2,
  preHeading,
  heading,
  description,
  activeSlide,
  openVideoText,
  bgColor = 'dark',
  bgImage,
  step_1_hasExternalResource,
}) => {
  const isMobile = useCheckMobile({ width: 1024 });
  const [isOpened, setOpenedStatus] = useState(false);

  function openModal() {
    setOpenedStatus(true);
  }

  function onClose() {
    setOpenedStatus(false);
  }

  useEffect(() => {
    if (isOpened && activeSlide === 1) {
      onClose();
    }
  }, [activeSlide, isOpened]);

  console.log(activeSlide);

  return (
    <div
      className="custom-slide"
      style={{
        backgroundImage: `url(${slideImage})`,
      }}
    >
      <div className="mobile-image-wrapper">
        <img className="mobile-image" src={slideImage} />
        <div className="mobile-headings">
          <p>{preHeading}</p>
          <h3>{heading}</h3>
        </div>
      </div>
      <div
        className={`use-case__steps-mobile use-case__steps-mobile_${bgColor}`}
      >
        <div
          onClick={step_1_hasExternalResource ? openModal : undefined}
          className={cn(
            'step-image-container',
            step_1_hasExternalResource && 'image-clickable',
          )}
        >
          <img
            style={{
              background: bgImage,
            }}
            src={stpImage_1}
          />
        </div>
        <div className="step-image-container">
          <img
            style={{
              background: bgImage,
            }}
            src={stpImage_2}
          />
        </div>
      </div>
      <div className="carousel_shadow" />
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        transition={{ duration: 0.75 }}
        variants={
          isMobile
            ? null
            : {
                visible: { x: 0, opacity: 1 },
                hidden: { x: -400, opacity: 0 },
              }
        }
        className="carousel__description"
      >
        <p className="carousel__description-label">{preHeading}</p>
        <h3>{heading}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(description, ['target']),
          }}
        ></p>

        {openVideoText && (
          <p
            className="carousel__video-link"
            onClick={() => setOpenedStatus(true)}
          >
            {openVideoText}
          </p>
        )}
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        transition={{ duration: 0.75 }}
        variants={{
          visible: { x: 0, opacity: 1 },
          hidden: { x: 400, opacity: 0 },
        }}
        className={`use-case__steps use-case__steps_${bgColor}`}
      >
        <div
          onClick={step_1_hasExternalResource ? openModal : undefined}
          className={cn(
            'step-image-container',
            step_1_hasExternalResource && 'image-clickable',
          )}
        >
          <img
            style={{
              background: bgImage,
            }}
            src={stpImage_1}
          />
        </div>
        <div className="step-image-container">
          <img
            style={{
              background: bgImage,
            }}
            src={stpImage_2}
          />
        </div>
      </motion.div>
      {isOpened && (
        <Modal
          isOpened={isOpened}
          videoUrl={'https://www.youtube.com/embed/dmIMq9vXDoU'}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default Slide;
