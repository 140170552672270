import React from 'react';
import { motion } from 'framer-motion';

import { sanitizeHtml } from 'utils';
import { useCheckMobile } from '../../hooks';

import './ImageWithDescription.scss';

const ImageWithDescription = ({ icon, image, title, description, animationDirection }) => {
  const isMobile = useCheckMobile({ width: 1024 });
  return (
    <div className={`iwd-content`}>
      <div className="iwd-content__text">
        <img src={icon} />
        <h3>{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}></p>
      </div>
      <div className="iwd-content__image-wrapper">
        <motion.img
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.75 }}
          variants={
            isMobile
              ? null
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: animationDirection, opacity: 0 },
                }
          }
          src={image}
          alt="lego bunnies"
        />
      </div>
    </div>
  );
};

export default ImageWithDescription;
