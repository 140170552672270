import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import arrowLeft from '../../../asset/resource/slider-arrow-left.svg';
import arrowRight from '../../../asset/resource/slider-arrow-right.svg';
import Slide from '../Slide/Slide';

import { items } from '../../resources/carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CustomCarousel.scss';

const CustomCarousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Carousel
      onChange={(idx) => setActiveSlide(idx)}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={30}
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            className="use-case__control-btn use-case__control-btn-left"
            type="button"
            onClick={onClickHandler}
          >
            <img className="custom-control" src={arrowLeft} />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            className="use-case__control-btn use-case__control-btn-right"
            type="button"
            onClick={onClickHandler}
          >
            <img className="custom-control" src={arrowRight} />
          </button>
        )
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        const defStyle = { marginLeft: 30, color: 'gray', cursor: 'pointer' };
        const style = isSelected
          ? { ...defStyle, color: 'white' }
          : { ...defStyle };
        return (
          <span
            className="use-case__slide-index"
            style={style}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          >
            {index + 1}
          </span>
        );
      }}
    >
      {items.map((slide) => {
        return (
          <Slide key={slide.heading} {...slide} activeSlide={activeSlide} />
        );
      })}
    </Carousel>
  );
};

export default CustomCarousel;
