import React from 'react';
import cn from 'classnames';

import './Modal.scss';

const Modal = ({ videoUrl, isOpened, onClose, children, className }) => {
  return (
    <div className={cn('video-modal', className)}>
      {isOpened && (
        <div className="video-modal__modal" onClick={onClose}>
          {!children ? (
            <div className="video-modal__modal__content">
              <button className="video-modal__modal__close" onClick={onClose}>
                ✖
              </button>
              <iframe
                id="youtube"
                frameBorder="0"
                allowFullScreen
                width="100%"
                height="100%"
                allow="autoplay"
                src={`${videoUrl}?autoplay=1&rel=0`}
              />
            </div>
          ) : (
            <div className="video-modal__modal__content">{children}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Modal;
