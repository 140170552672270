import DOMPurify from 'isomorphic-dompurify';

export function sanitizeHtml(html, allowedAttr) {
  let cleanedData = DOMPurify.sanitize(html, { ADD_ATTR: allowedAttr });
  return cleanedData;
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}
