import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import RegistrationForm from '../../components/RegistrationForm/RegistratinoForm';
import ImageWithDescription from '../../components/ImageWithDescription/ImageWithDescription';
import UseCard from '../../components/UseCard/UseCard';
import CustomCarousel from '../../components/Carousel/CustomCarousel';

import { useCheckMobile } from '../../hooks';

//Assets
import softserve_logo from '../../../asset/resource/softserve_logo.png';
import legoLeft from '../../../asset/resource/lego-left.png';
import legoRight from '../../../asset/resource/lego-right.png';
import legoGIF from '../../../asset/resource/bunny_lego.png';
import badge from '../../../asset/resource/soft-serve-badge.png';
import arrowIcon from '../../../asset/resource/arrow-icon.svg';
import Scanner_3D from '../../../asset/resource/Scanner_3D.mp4';

import { navLinks } from '../../resources/navigation';
import { howItWorks, howToUse } from '../../resources/content';

import './Main.scss';

function Main() {
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const isMobile = useCheckMobile({ width: 1024 });
  const isMobileForHeader = useCheckMobile({ width: 767 });

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);

    const video = document.getElementById('background-video');
    setTimeout(function () {
      video.play();
    }, 100);
  }, [isSafariBrowser]);

  return (
    <div className="main" id="home">
      <Header logo={softserve_logo} isMobile={isMobileForHeader} links={navLinks} />
      <div className="hero-main">
        <div className="hero-main__title">
          <h1>
            Boost manufacturing quality with
            <br /> Automated 3D Scanning
          </h1>
          <p>
            Our Automated 3D Scanning Demo lets you try out structured-light 3D scanning technology. Discover the
            potential of visual automated inspections. 
          </p>
        </div>
        <p className="hero-main__more">
          Discover&nbsp;more <img className="hero-main__more-icon" src={arrowIcon} />
        </p>
        <video id="background-video" preload="auto" loop muted playsInline className="hero-main__video">
          <source src={Scanner_3D} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <section className="intro">
        <div className="intro__text">
          <p>Discover the potential and possibilities of visual automated inspection for the manufacturing industry.</p>
          <p>
            This technology integrates with assembly lines and production processes. <br />
            The integration means less time spent on manual inspections. Instead, you'll focus on the critical work of
            accelerating shop floor operations.
          </p>
        </div>
        <div className="intro__lego">
          <p>Test the reliability and precision of Automated 3D Scanning for yourself!</p>
          <div className="intro__image-wrapper">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? null
                  : {
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: [-200, 50, 0], opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1 }}
              src={legoLeft}
            />
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? null
                  : {
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: [200, -50], opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1 }}
              src={legoRight}
            />
          </div>
          <p>
            Place an object in the scanner and study its rendering.
            <br /> Did you catch as many defects as the scanner?{' '}
          </p>
          <ScrollLink smooth to="try-it-out" offset={0} duration={1000}>
            Try it out
          </ScrollLink>
        </div>
      </section>

      <section id="how-it-works" className="how-it-works">
        <div className="how-it-works__title">
          <p>How it works</p>
          <h2>
            EXPLORE THE TECHNOLOGY <br className="mobile-hidden" /> BEHIND OUR AUTOMATED{' '}
            <br className="mobile-hidden" /> 3D SCANNING DEMO
          </h2>
        </div>
        <div className="how-it-works__content">
          {howItWorks.map((item, idx) => {
            return (
              <ImageWithDescription key={item.title} {...item} animationDirection={idx % 2 === 0 ? '400' : '-400'} />
            );
          })}
        </div>
      </section>

      <section id="how-to-use" className="how-to-use">
        <div className="how-to-use__title">
          <p>How to use</p>
          <h2>INDUSTRIAL USES FOR AUTOMATED 3D SCANNING</h2>
        </div>
        <div className="how-to-use__cards-container">
          {howToUse.map((item, idx) => {
            return <UseCard key={item.title} {...item} animationDirection={idx % 2 === 0 ? '-400' : '400'} />;
          })}
        </div>
      </section>

      <section id="use-cases" className="use-cases">
        <CustomCarousel />
      </section>

      <section id="try-it-out" className="try-it-out">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? null
              : {
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1 }}
          className="try-it-out__title"
        >
          <p>Try it out</p>
          <h2>CONDUCT YOUR OWN INSPECTION</h2>
        </motion.div>
        <div className="try-it-out__content">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? null
                : {
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: -400, opacity: 1 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1 }}
            src={legoGIF}
          />

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? null
                : {
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: 400, opacity: 0 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1 }}
            className="try-it-out__content-text"
          >
            <p>
              Experience the qualitative benefits of Automated 3D Scanning!
              <br /> You have two options:
            </p>
            <p class="list-title">Packaging Inspection</p>
            <ol>
              <li>Place a package in the scanner and select a target model. </li>
              <li>Scan the package to see if its text, dimensions, and barcode match the target.</li>
              <li>Evaluate discrepancies for possible defects. </li>
            </ol>
            <p class="list-title">Dimensional Inspection </p>
            <ol>
              <li>Place an object in the scanner and select its corresponding target model.</li>
              <li>Select comparison mode.</li>
              <li>View a heatmap of the object’s deviations from the model. </li>
            </ol>
          </motion.div>
        </div>

        <div className="assistance-wrapper">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: '-100px 0px 0px 0px' }}
            variants={
              isMobile
                ? null
                : {
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 100, opacity: 0 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1 }}
            id="try it out"
            className="try-it-out__assistance"
          >
            <img src={badge} />
            <div className="try-it-out__assistance-text">
              <p>Need assistance?</p>
              <p>
                Ask a friendly SoftServian at our demo station to walk you through the Automated 3D Scanning process. It
                takes no more than three minutes.
              </p>
            </div>
            {/* <div className="try-it-out__assistance-text-highlight">
              Don’t forget to take both your physical and digital — we’ll give
              you a special code! — models home with you.
            </div> */}
          </motion.div>
        </div>
        {/* <div className="try-it-out__assistance-text-highlight_mobile">
          Don’t forget to take both your physical and digital — we’ll give you a
          special code! — models home with you.
        </div> */}
      </section>

      <section className="lets-talk">
        <div className="lets-talk__info-wrapper">
          <div className="lets-talk__info">
            <h2>
              Add Automated 3D Scanning <br className="mobile-hidden" /> Demo to your event!
            </h2>
            <p>Share SoftServe’s Automated 3D Scanning experience and showcase its revolutionary technology.</p>
            <ScrollLink smooth to="contact-us" offset={0} duration={1000}>
              Let's talk!
            </ScrollLink>
          </div>
        </div>
      </section>

      <section id="contact-us" className="demo-request">
        <div className="demo-request__content">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? null
                : {
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: -200, opacity: 0 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1 }}
            className="demo-request__text"
          >
            <h2>REQUEST A DEMO</h2>
            <p>
              3D scanning can dramatically improve quality inspection for your organization. Submit the form and we will
              reach out!
              <br />
            </p>
          </motion.div>
          <RegistrationForm />
        </div>
      </section>

      <Footer logo={softserve_logo} />
    </div>
  );
}

export default Main;
