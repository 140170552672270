import icon_1 from '../../asset/resource/icon-1.svg';
import icon_2 from '../../asset/resource/icon-2.svg';
import icon_3 from '../../asset/resource/icon-3.svg';
import icon_4 from '../../asset/resource/icon-4.svg';
import image_1 from '../../asset/resource/image-1.png';
import image_2 from '../../asset/resource/image-2.png';
import image_3 from '../../asset/resource/image-3.png';
import image_4 from '../../asset/resource/image-4.png';

import use_image_1 from '../../asset/resource/use-image-1.svg';
import use_image_2 from '../../asset/resource/use-image-2.svg';
import use_image_3 from '../../asset/resource/use-image-3.svg';
import use_image_4 from '../../asset/resource/use-image-4.svg';

export const howItWorks = [
  {
    icon: icon_1,
    title: 'Structured light scanning unit',
    description: `Hardware powered by a SoftServe SDK carries out the scanning process. <br/><br/>
    The 3D scanner uses projected light and a camera. It calculates where light touches the object from every angle. This generates a surface 3D point cloud with an accuracy of up to 0.1 mm. `,
    image: image_1,
  },
  {
    icon: icon_2,
    title: 'Holistic Hardware',
    description: `Automated 3D Scanning is a standalone solution outfitted with a post-processor. It generates a 3D mesh using scanned images and point cloud, further leveraging texture auto-generation to provide realistic surface detail.<br/><br/>
    The portable version uses an automated 360-degree turntable to capture the scanned object from every angle. Its modular design allows it to be easily adapted and modified for different settings. `,
    image: image_2,
  },
  {
    icon: icon_3,
    title: 'Customized Software',
    description: `SoftServe's automated 3D scanning solution has a suite of customizable software at its core. <br/><br/>
    Combined with AI and advanced deterministic algorithms, this scanning solution offers:<br/>
    <ul>
      <li>Precise alignment of scanned models with CAD designs </li>
      <li>Sophisticated text analysis </li>
      <li>Efficient barcode reading</li>
      <li>Accurate production flaw identification</li>
    </ul>`,
    image: image_3,
  },
  {
    icon: icon_4,
    title: 'UX',
    description: `An onboard touchscreen operates and configures the scanner. <br/><br/>
    Connect a companion touchscreen device to view current and recent scans and to manage your full library.`,
    image: image_4,
  },
];

export const howToUse = [
  {
    image: use_image_1,
    title: 'Reduce manual&nbsp;work',
    description: `Reduce specialized labor dependency.
    Produce automated quality process reliability across multiple production stages. `,
  },
  {
    image: use_image_2,
    title: 'Speed up production',
    description: `3D scanning technology allows consistent accuracy, at high speeds, for long periods of operation. This decreases order lead times so you can ship products faster.`,
  },
  {
    image: use_image_3,
    title: 'Improve quality',
    description: `Accurately detect individual component measurements, misplacement levels, and defects.`,
  },
  {
    image: use_image_4,
    title: 'Reduce costs',
    description: `Scanning efficiency identifies defects earlier in production and saves resources. Automation reduces manual labor.`,
  },
];
