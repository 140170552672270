import React from 'react';
import { motion } from 'framer-motion';

import { sanitizeHtml } from 'utils';
import { useCheckMobile } from '../../hooks';

import './UseCard.scss';

const UseCard = ({ title, description, image, animationDirection }) => {
  const isMobile = useCheckMobile({ width: 1024 });
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.75 }}
      variants={
        isMobile
          ? null
          : {
              visible: { x: 0, opacity: 1 },
              hidden: { x: animationDirection, opacity: 0 },
            }
      }
      className="use-card"
    >
      <div className="use-card__text">
        <h3 dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }} />
        <p>{description}</p>
      </div>
      <div className="use-card__image-wrapper">
        <img src={image} />
      </div>
    </motion.div>
  );
};

export default UseCard;
